.login-page {
  background-image: url('./background.jpeg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.login-page .left-panel, .login-page .right-panel {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.login-page .right-panel {
  background-color: white;
}

.login-form {
  max-width: 300px;
}
.login-form-forgot {
  float: right;
}
.ant-col-rtl .login-form-forgot {
  float: left;
}
.login-form-button {
  width: 100%;
}