.home-page {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.home-page .left-panel, .home-page .right-panel {
  display: flex;
  height: 60px;
  justify-content: flex-start;
  align-items: center;
}

.home-page .right-panel {
  background-color: white;
  justify-content: space-between;
  padding-right: 12px;
}

.home-page .main-panel {
  height: calc(100vh - 60px);
  width: 100vw;
  overflow-y: auto;
}

.drawer-menu .ant-drawer-body{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.drawer-menu .ant-drawer-body .top-menu-panel .ant-menu-item{
  padding-left: 24px;
}

.drawer-menu .ant-drawer-body .bottom-menu-panel {
  padding: 24px;
}